import React, { useMemo } from 'react'
import { get } from 'lodash'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'

const ArticleText = ({ data, index }) => {

  // console.log('[ArticleText.js] data', data)

  const content = useMemo(() => (
    get(data, 'primary.content')
  ), [data])

  return(
    <section
      key={index}
      className={"article_text"}>

      <div className="row row-centered">
        <div className="col col6 font-book">
          <RichText
            render={content}
            linkResolver={linkResolver}
          />
        </div>
      </div>

    </section>
  )  
}

export default ArticleText
