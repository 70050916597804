import React, { useMemo } from 'react'
import { get } from 'lodash'
import { Strings } from '../../utils/strings'

const ArticleShare = ({ data, index, page }) => {

  // console.log('[ArticleShare.js] page', page)

  const title = useMemo(() => (
    get(page, 'title[0].text')
  ), [page])

  // const text = useMemo(() => (
  //   get(data, 'primary.text')
  // ), [data])

  const titleEncoded = encodeURI(title)

  const currentUrl = window.location.href

  const allStrings = Strings(page)

  return(
    <section
      key={index}
      className={"article_share"}>

      <div className="row row-centered">
        <div className="col col6 font-book">
          <h6 className="label-l">
            {allStrings?.article_share ?? 'Share'}
          </h6>
          <p className="p-large">
            <a href={"https://www.facebook.com/sharer.php?u="+currentUrl} target="_blank" rel="noreferrer">
              Facebook
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href={"https://twitter.com/share?text="+titleEncoded+"&url="+currentUrl} target="_blank" rel="noreferrer">
              Twitter
            </a>
          </p>
        </div>
      </div>

    </section>
  )  
}

export default ArticleShare
