import React, { useMemo } from 'react'
import { get } from 'lodash'

const ArticleHeader = ({ data, index }) => {

  // console.log('[HomeHeader.js] data', data)

  const title = useMemo(() => (
    get(data, 'title[0].text')
  ), [data])

  const intro = useMemo(() => (
    get(data, 'intro[0].text')
  ), [data])

  // const meta = useMemo(() => (
  //   get(data, '_meta')
  // ), [data])

  const lang = useMemo(() => (
    get(data, '_meta.lang')
  ), [data])

  const main_image = useMemo(() => (
    get(data, 'main_image')
  ), [data])

  const date = new Date(get(data, 'date'))

  const dateOptions = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  }

  const dateText = date.toLocaleDateString(lang, dateOptions)

  return(
    <section
      key={index}
      className={"article_header"}>

      <div className="row row-centered">
        <div className="col col6">
          <h1>
            {title}
          </h1>

          <h2 className="p-large font-book">
            {intro}
          </h2>

          <h4>
            {dateText}
          </h4>
        </div>

        <div className="col col12 article_header__cover">
          <img 
            src={main_image?.url} 
            alt={main_image?.alt ?? title}
          />
        </div>
      </div>

    </section>
  )  
}

export default ArticleHeader
