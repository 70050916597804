import React, { useMemo } from 'react'
import { get } from 'lodash'
// import ReactPlayer from 'react-player'
// // import LazyLoad from 'react-lazyload'
// // import { RichText } from 'prismic-reactjs'
// // import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// // import '../../../node_modules/uikit/dist/js/uikit.js'
// import { useWindowSizes } from '@hooks/window'
// import { Waypoint } from 'react-waypoint'
// import LogoImg from '../../images/logo.svg'
// import { RichText } from 'prismic-reactjs'

const ArticleImage = ({ data, index }) => {

  // console.log('[ArticleImage.js] data', data)

  const image = useMemo(() => (
    get(data, 'primary.image')
  ), [data])

  const label = useMemo(() => (
    get(data, 'primary.label[0].text')
  ), [data])

  return(
    <section
      key={index}
      className={"article_image"}>

      <div className="row row-centered">
        <div className="col col6 font-book">
          <img src={image?.url} alt={image?.alt ?? label} />
          {label !== '' ?
            <label className="article_image__label">
              {label}
            </label>
          : null}          
        </div>
      </div>

    </section>
  )  
}

export default ArticleImage
