import React, { useMemo } from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
// import HomeHeader from '../components/slices/HomeHeader'
// import WideImage from '../components/slices/WideImage'
// import HeroLead from '../components/slices/HeroLead'
// import TitleBar from '../components/slices/TitleBar'
// import LeadAndText from '../components/slices/LeadAndText'
// import SecondaryHeader from '../components/slices/SecondaryHeader'
// import PeopleCards from '../components/slices/PeopleCards'
// import EditorialTextImage from '../components/slices/EditorialTextImage'
// import IlustrationEditorialBanner from '../components/slices/IlustrationEditorialBanner'
// import EditorialTextImageContact from '../components/slices/EditorialTextImageContact'
import ArticleHeader from "../components/slices/ArticleHeader"
import ArticleText from "../components/slices/ArticleText"
import ArticleQuote from "../components/slices/ArticleQuote"
import ArticleShare from "../components/slices/ArticleShare"
// import NewsletterFrom from '../components/slices/NewsletterFrom'
import ArticleImage from "../components/slices/ArticleImage"

// Display the title, date, and content of the Post
const ArticleBody = ({ page }) => {
  // console.log('[page.js] page', page)

  const renderContainer = (container, index) => {
    console.log("container", container)

    if (container && container.type === "text") {
      return <ArticleText key={index} data={container} index={index} />
    }

    if (container && container.type === "image") {
      return <ArticleImage key={index} data={container} index={index} />
    }

    if (container && container.type === "quote") {
      return <ArticleQuote key={index} data={container} index={index} />
    }

    // if (container && container.type === "the_team") {
    //   return (<PeopleCards key={index} data={container} index={index} />)
    // }

    // if (container && container.type === "illustration_banner") {
    //   return (<IlustrationEditorialBanner key={index} data={container} index={index} />)
    // }

    // if (container && container.type === "contact_us") {
    //   return (<EditorialTextImageContact key={index} data={container} index={index} />)
    // }

    return []
  }

  if (!page) return null

  return (
    <section>
      <div className="containerGlobal article_body">
        <ArticleHeader data={page} />

        {page &&
          page?.body?.map((container, index) =>
            renderContainer(container, index)
          )}

        <ArticleShare page={page} />

        {/* TODO Add when connecting with hubspot */}
        {/* <NewsletterFrom /> */}
      </div>
    </section>
  )
}

export default props => {
  if (!props) return null

  const doc = props.data.prismic.allArticles.edges[0]

  if (!doc) return null

  const meta = doc.node._meta

  const articleTitle = useMemo(() => get(doc, "node.title[0].text"), [doc])

  const seoTitle = useMemo(() => get(doc, "node.seo_title[0].text"), [doc])

  const seoText = useMemo(() => get(doc, "node.seo_meta[0].text"), [doc])

  const seoImage = useMemo(
    () => get(doc, "node.seo_image.url") || get(doc, "node.main_image.url"),
    [doc]
  )

  return (
    <Layout pathname={props.path} meta={meta}>
      <SEO
        title={
          seoTitle
            ? seoTitle
            : articleTitle
            ? articleTitle
            : "Transparent, Sustainable and Direct"
        }
        description={seoText}
        image={seoImage}
      />
      <ArticleBody page={doc.node} />
    </Layout>
  )
}

export const query = graphql`
  query Article($lang: String, $uid: String) {
    prismic {
      allArticles(lang: $lang, uid: $uid) {
        edges {
          node {
            _meta {
              uid
              lang
              type
              alternateLanguages {
                lang
                uid
                type
              }
            }
            title
            main_image
            intro
            date
            body {
              ... on PRISMIC_ArticleBodyText {
                type
                label
                primary {
                  content
                }
              }
              ... on PRISMIC_ArticleBodyQuote {
                type
                label
                primary {
                  details
                  quote
                }
              }
              ... on PRISMIC_ArticleBodyImage {
                type
                label
                primary {
                  image
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`
