import React, { useMemo } from 'react'
import { get } from 'lodash'


const ArticleQuote = ({ data, index }) => {

  // console.log('[ArticleQuote.js] data', data)

  const quote = useMemo(() => (
    get(data, 'primary.quote[0].text')
  ), [data])

  const details = useMemo(() => (
    get(data, 'primary.details[0].text')
  ), [data])

  return(
    <section
      key={index}
      className={"article_quote"}>

      <div className="row row-centered">
        <div className="col col10">
          <h2>
            {quote}          
          </h2>
          {details !== "" ?
            <p className="h4">
              {details}
            </p>
          : null}
          
        </div>
      </div>

    </section>
  )  
}

export default ArticleQuote
